/*  
    Arquivo de estilo do site

    stars_L.svg = 01
    stars_M.svg = 02
    stars_S.svg = 03
*/

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Inter UI", sans-serif;
  text-align: center;
  color: white;
}

.titan-background {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(-180deg, #000000 0%, #200835 100%);
  overflow: hidden;
  z-index: 1;
  /* Make the rubber band scroll not show empty white space */
  top: -10%;
  height: 120%;
  min-height: 120vh;
  margin-bottom: -20%;
}

.stars {
  position: absolute;
  top: 0;
  width: 300vw;
  height: 100vh;
  transform: translate(0%, 0%);
  background-size: contain;
  background-repeat: repeat;
  transform-origin: top left;
}

.stars-L {
  background-image: url("https://thanos-juca.netlify.app/img/stars-L.svg"); /* Caminho relativo para a imagem na pasta public */
  background-size: 890px;
  height: 750px;
  animation: panning-stars 200s linear infinite alternate;
}

.stars-M {
  background-image: url("https://thanos-juca.netlify.app/img/stars-M.svg"); /* Caminho relativo para a imagem na pasta public */
  height: 530px;
  animation: panning-stars 300s linear infinite alternate;
}

.stars-S {
  background-image: url("https://thanos-juca.netlify.app/img/stars-S.svg"); /* Caminho relativo para a imagem na pasta public */
  height: 370px;
  animation: panning-stars 400s linear infinite alternate;
}


@keyframes panning-stars {
  from {
    transform: translate(-20%, 0%);
  }
  to {
    transform: translate(-80%, -40%);
  }
}

.marvellous-container {
  position: relative;
  z-index: 2;
  padding: 104px 0 24px 0;
  width: 95%;
  max-width: 600px;
  margin: auto;
}

h1 {
  display: block;
  font-size: 80px;
  margin: 0 0 24px 0;
}

@media (max-width: 500px) {
  h1 {
    font-size: 16vw;
  }
}

h2 {
  font-weight: 500;
  font-size: 24px;
}

h3 {
  font-weight: 300;
  font-size: 24px;
}

a {
  color: inherit;
  text-decoration: none;
}

.title-marvel {
  font-weight: bolder;
  color: #020103;
  letter-spacing: -6.7px;
  text-transform: uppercase;
  padding: 0 16px;
  background: #9b00f5;
}

.title-thanos{
  font-weight: bolder;
  color: #020103;
  letter-spacing: -6.7px;
  text-transform: uppercase;
  padding: 0 16px;
  background: #9b00f5;
  margin-bottom: 1px;
}

.title-juca{
  color: #9b00f5;
  border-top: 8px solid;
  border-bottom: 8px solid;
  border-color: #9b00f5;
}

code {
  display: block;
  box-sizing: border-box;
  text-align: left;
  font-size: 18px;
  width: 100%;
  padding: 16px 24px;
  background: rgba(44, 37, 44, 0.8);
  border: 1px solid #7f797f;
  border-radius: 8px;
  margin-bottom: 16px;
}

.header {
  margin-bottom: 104px;
}

.installation-instructions,
.usage-instructions {
  margin-top: 64px;
}

.footer {
  margin-top: 224px;
}

.footer img {
  height: 104px;
  width: auto;
}

.aeus67Ac{
  color: #9b00f5;
}
